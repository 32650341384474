<template>
  <div class="Serve">
    <div class="banner">
      <div class="container">
      </div>
    </div>
    <div class="contts pd40">
      <div class="container">
        <el-row :gutter="24">
          <el-col :span="8">
            <div class="_navList">
              <a v-for="(item,index) in navList"
                 :href="'/home/visa-center-detail/'+item.id"
                 :class="item.id == (this.$route.params.id == 'index' ? navList[0].id:this.$route.params.id) ? 'on':'' "
                 class="_lis"
                 :key="index">
                <div class="imgbox">
                  <img src="@/assets/jiantou2.png"
                       alt="">
                </div>
                <p> {{item.title}}</p>
              </a>
            </div>
          </el-col>
          <el-col :span="16">
            <router-view></router-view>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import axiosApi from "../api/api.js";
import { ref, markRaw } from "vue";

export default {
  data () {
    return {
    }
  },
  setup () {
    const navList = ref([])
    return {
      navList
    }
  },
  beforeMount () {
    axiosApi(
      'news/20', {}, 'get'
    ).then((res) => {
      console.log(res.data.data)
      let arr = markRaw(this.navList)
      arr = res.data.data
      this.navList = markRaw(arr)
    })
  },
}
</script>

<style lang="scss" scoped>
.banner {
  background: url(~@/assets/neibanner1.jpg) top center no-repeat;
  height: 400px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    .title {
      font-size: 38px;
      font-weight: bold;
      color: #fff;
      letter-spacing: 4px;
    }
  }
}
.list {
  .lis {
    display: block;
    margin-bottom: 20px;
    border: 1px solid #dedede;

    .imgbox {
      width: 100%;
      height: 230px;
    }
    .text {
      margin-top: 10px;
      padding: 20px;
      .date {
        color: #0c3879;
        font-size: 14px;
      }
      .head {
        font-size: 17px;
        font-weight: bold;
        margin-bottom: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #333;
        transition: ease 0.5s;
      }
      .cont {
        font-size: 14px;
        line-height: 24px;
        height: 48px;
        color: #666;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        transition: ease 0.5s;
        margin-bottom: 15px;
      }
    }
    .more {
      text-decoration: underline;
      transition: ease 0.5s;
    }
    &:hover {
      .head {
        color: #0c3879;
      }
      .more {
        color: #ff7e00;
      }
    }
  }
}
._navList {
  ._lis {
    height: 40px;
    padding: 0 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: calc(100% - 20px);
    }
    .imgbox {
      margin-right: 5px;
    }
    background: url(~@/assets/newsbg1.jpg) center center repeat-x;
    &:hover,
    &.on {
      background: url(~@/assets/newsbg2.jpg) center center repeat-x;
      color: #fff;
    }
  }
}
.tit {
  border-left: 8px solid #0c3879;
  padding-left: 10px;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
  margin-top: 30px;
  line-height: 1;
}
.lslis {
  display: block;
  margin-bottom: 20px;
  h3 {
    font-size: 14px;
  }
}
</style>